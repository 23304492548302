export const SalesNav = [
  {
    icon: "pe-7s-cash",
    label: "Catálogo",
    content: [
      {
        label: "Cadastros",
        content: [
          {
            label: "Produtos",
            to: '/#/produtos'
          },
          {
            label: "Clientes",
            to: '/#/clientes'
          },
          {
            label: "Fornecedores",
            to: '/#/fornecedores'
          },
          {
            label: "Transportadoras",
            to: '/#/transportadoras'
          },
          {
            label: "Vendedores",
            to: '/#/vendedores'
          },
          {
            label: "Impostos",
            to: '/#/impostos'
          },
          {
            label: "Estoque",
            to: '/#/estoque'
          }
        ]
      }
    ]
  }
];

export const PurchasesNav = [
  {
    icon: "pe-7s-shopbag",
    label: "Pedidos",
    to: ''
  }
];

export const FinancialNav = [
  {
    icon: "pe-7s-piggy",
    label: "Canais",
    content: [
      {
        label: "Serviços Integrados",
        content: [
          {
            label: "Plataformas",
            to: '/#/platatormas'
          },
          {
            label: "ERPs",
            to: '/#/erps'
          },
          {
            label: "Logisticas",
            to: '/#/logisticas'
          },
          {
            label: "Ferramentas",
            to: '/#/ferramentas'
          }
        ]
      },
      {
        label: "Adicionar Canal (só nf)",
        to: '/#/produtos'
        // to: "/#/add_canal"
      },
      {
        label: "Adicionar Integração",
        to: "/#/add_integracao"
      }
    ]
  }
];

export const ReportsNav = [
  {
    icon: "pe-7s-graph1",
    label: "Relatórios",
    to: ''
  }
];
